import React, { FC, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { matchTrackerModel } from 'entities/match-tracker'

import {
  StyledTrackerWrapper,
  StyledCloseIcon
} from './live-match-tracker.styled'

export const LiveMatchTracker: FC = () => {
  const dispatch = useDispatch()
  const currentMatch = useSelector(matchTrackerModel.selectCurrentMatch)

  useEffect(() => {
    if (currentMatch) {
      window.SIR('addWidget', '#live-match-tracker-widget', 'match.lmtPlus', {
        matchId: currentMatch
      })
    }
  }, [currentMatch])

  const handleClick = useCallback(
    () =>
      dispatch(matchTrackerModel.liveMatchTrackerActions.clearCurrentMatch()),
    [dispatch]
  )

  if (!currentMatch) {
    return null
  }

  return (
    <StyledTrackerWrapper>
      <StyledCloseIcon size={10} onClick={handleClick} />
      <div id="live-match-tracker-widget" />
    </StyledTrackerWrapper>
  )
}
