import styled from 'styled-components'

import { IconClose } from 'shared/ui/Icon/General/IconClose'

export const StyledTrackerWrapper = styled.div`
  max-width: 280px;
  position: relative;
`

export const StyledCloseIcon = styled(IconClose)`
  cursor: pointer;
  position: absolute;
  right: 3px;
  top: 3px;
  z-index: 2;
  svg {
    color: ${({ theme }) => theme.colors.fixed.primary[90]};
  }
`
